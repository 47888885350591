import { LinkStoryblok } from '@/types/types-storyblok';

/**
 * Resolves a web link from a storyblok link object
 * @param link - The link object from storyblok
 * @param reference - An optional reference to help debug the link
 */
export const resolveWebLink = (link: NonNullable<LinkStoryblok['link']>, reference?: any) => {
  let url = typeof link.url === 'string' && !!link.url ? link.url : link.cached_url;
  if (!url) {
    console.warn(`Unable to resolve link: ${JSON.stringify(link)}`, reference);
    return '#';
  }
  if (!url.startsWith('/') && !url.startsWith('http')) {
    url = `/${url}`;
  }
  return url;
};
