'use client';

import { useIsTantosvago } from '@/app/_hooks/useIsTantosvago';
import { FooterStoryblok } from '@/types/types-storyblok';
import FooterTop from '@/app/_components/layouts/footer/footerWrapper/footerTop';
import FooterBottom from '@/app/_components/layouts/footer/footerWrapper/footerBottom';
import dynamic from 'next/dynamic';

const FooterTantosvago = dynamic(() => import('@/app/_components/layouts/footer/footerWrapper/footerTantosvago'));

const FooterWrapper: React.FC<{
  component: FooterStoryblok | undefined;
  componentTantosvago: FooterStoryblok | undefined;
  minimal: boolean;
}> = ({ component, componentTantosvago, minimal }) => {
  const isTantosvago = useIsTantosvago();
  if (componentTantosvago?.component === 'Footer' && isTantosvago) return <FooterTantosvago component={componentTantosvago} />;

  if (component?.component === 'Footer') {
    return (
      <>
        {!minimal && <FooterTop component={component} />}
        <FooterBottom component={component} minimal={minimal} />
      </>
    );
  }
  return null;
};
export default FooterWrapper;
